import React from "react";

const HomeAboutSection = ({}:{}) => {
  return <div className="section-about">
      <div className="uk-container">
          <div className="section-content">
              <div className="uk-grid uk-grid-large uk-child-width-1-2@m" data-uk-grid={true}>
                  <div><img src="/assets/img/cover/cover-7.jpeg" alt="img-about" /></div>
                  <div>
                      <div className="section-title">
                          <div className="uk-text-meta">A propos de <strong className="text">SME</strong></div>
                          <div className="uk-h2">Nous vous offrons des engins de meilleure qualité pour vos projets aux meilleurs prix.</div>
                      </div>
                      <div className="section-content">
                          <p>SME Sarl est une société malienne qui à pour objet en République du Mali et à l'Etranger:</p>
                          <ul className="list-checked">
                              <li>Vente et Location des équipements d'occasions ou neufs</li>
                              <li>Vente des machines e t d'engins</li>
                              <li>Approvisionnement du marché local en besoin de matériels ou équipements</li>
                          </ul>
                      </div>
                      <p>Et généralement ,toutes opérations industrielles, commerciales,
                          mobilières ,immobilières et financières pouvant se rattacher directement aux activités sus indiquées ou concourir à leur développement.</p>
                      {/*<ul className="about-list">
                          <li><strong>La qualité<br/>Au bon prix</strong><span>Velit esse cillum dolore ipsum eu fugiat nulla pariatur. </span>
                          </li>
                          <li><strong>Une expertise<br/>des engins</strong><span>Excepteur sint occaecat cupidat non proident sunt iny.</span>
                          </li>
                      </ul>*/}
                  </div>
              </div>
          </div>
      </div>
  </div>
}
export default HomeAboutSection;