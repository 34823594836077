import React from "react";
import SlidersSection from "./sliders-section";
import HomeCategoriesSection from "./home-categories-section";
import HomeAboutSection from "./home-about-section";
// import HomeCtaSection from "./home-cta-section";
// import HomeWhyChooseUsSection from "./home-why-choose-us-section";
import HomeEquipmentsSection from "./home-equipments-section";
import HomeReviewsSection from "./home-reviews-section";
// import HomeBlogSection from "./home-blog-section";

const Home = ({}:{}) => {

    return <>
        <SlidersSection/>
        <HomeCategoriesSection/>
        <HomeAboutSection/>
        {/*<HomeWhyChooseUsSection/>*/}
        <HomeEquipmentsSection/>
        {/*<HomeCtaSection/>*/}
        <HomeReviewsSection/>
        {/*<HomeBlogSection/>*/}
    </>
}

export default Home;