import React from "react";
import useSWR from "swr";
import {appUrls} from "../../util/api_constant";
import {post} from "../../services/BaseService";
import Loader from "../equipments/loader";
import CategoryViewer from "../equipments/category-viewer";
import CategoryEmpty from "../equipments/category-empty";

const HomeCategoriesSection = ({}:{}) => {
    const {data, error} = useSWR<any>(appUrls.topCategories, url => post(url, {}));

    return <div className="section-category">
      <div className="uk-container">
          <div className="section-title uk-text-center">
              <div className="uk-h2">Parcourez nos categories</div>
              <div className="uk-text-meta">Nous vous promettons de trouver le bon équipement pour vous</div>
          </div>
          <div className="section-content">
              {
                  (!data && !error)?
                      <Loader/> :
                      (data && data.data && data.data.length > 0)?
                          <div>
                              <div className="uk-grid uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s" data-uk-grid="true">
                                  {
                                      data.data.map((d: any) => (<CategoryViewer key={`cat_${d.id}`} category={d}/>))
                                  }
                              </div>
                              <div className="uk-margin-large-top uk-text-center"><a
                                  className="uk-button uk-button-default uk-button-large" href="/equipment-categories?group=all"><span>Toutes les categories</span><img
                                  src="/assets/img/icons/arrow.svg" alt="arrow" data-uk-svg={true} /></a></div>
                          </div>
                           : <CategoryEmpty/>
              }

          </div>
      </div>
  </div>
}

export default HomeCategoriesSection;