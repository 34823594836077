import React from "react";
import QuotationButton from "./quotation-button";

const HomeEquipmentsSection = ({}:{}) => {
  return <div className="section-equipment">
      <div className="uk-container">
          <div className="equipment-box">
              <div className="equipment-box__media"><img src="/assets/img/img-equipment.jpg" alt=""/></div>
              <div className="equipment-box__desc">
                  <div className="equipment-box__title">Vous vous faites des soucis à propos des coûts?</div>
                  <div className="equipment-box__text">Générez vous même votre devis dès aujourd'hui!</div>
                  <QuotationButton/>
                  {/*<div className="equipment-box__btn"><a className="uk-button uk-button-large"
                                                         href="/"><span>Demander un devis</span><img
                      src="/assets/img/icons/arrow.svg" alt="arrow" data-uk-svg={true} /></a></div>*/}
              </div>
          </div>
      </div>
  </div>
}

export default HomeEquipmentsSection;