import React from "react"
import {useEffect} from "react";
import Layout from "../components/layout/Layout";
import 'swiper/css';
import Home from "../components/home";
import {loadScript} from "../util/helper";
// markup
const IndexPage = () => {

    useEffect(() => {
        loadScript("/assets/js/libs.js");
        setTimeout(() => {
            loadScript("/assets/js/main.js");
        })

    })
  return (
      <Layout  title={'Le partenaire de votre business !'}>
          <Home/>
      </Layout>
  )
}

export default IndexPage
