import React from "react";
import QuotationButton from "./quotation-button";

const SlidersSection = ({}:{}) => {
  return <div className="section-slideshow">
      <div className="uk-position-relative uk-light"
           data-uk-slideshow="animation: fade; min-height: 400; max-height: 700; autoplay: true;">
          <ul className="uk-slideshow-items">
              <li className="slideshow-item"><img src="/assets/img/cover/cover-sme-1.jpeg" alt={"image"} data-uk-cover={true} />
                  <div className="slideshow-item__content">
                      <div className="uk-position-center uk-position-small uk-text-center">
                          <div className="header-icons" data-uk-slideshow-parallax="x: -200,200">
                              <span></span><span></span><span></span></div>
                          <p className="slideshow-item__desc"
                             data-uk-slideshow-parallax="x: 200,-200">Les bon engins au bon prix</p>
                          <h2 className="slideshow-item__title" data-uk-slideshow-parallax="x: -300,300">
                              Louer ou acheter les meilleurs engins <br/> au meilleur prix du marché !</h2>
                          <QuotationButton/>
                      </div>
                  </div>
              </li>
              <li className="slideshow-item"><img src="/assets/img/cover/cover-1.jpeg" alt={"image"} data-uk-cover={true} />
                  <div className="slideshow-item__content">
                      <div className="uk-position-center uk-position-small uk-text-center">
                          <div className="header-icons" data-uk-slideshow-parallax="x: -200,200">
                              <span></span><span></span><span></span></div>
                          <p className="slideshow-item__desc"
                             data-uk-slideshow-parallax="x: 200,-200">Le bon équipement au bon endroit !</p>
                          <h2 className="slideshow-item__title" data-uk-slideshow-parallax="x: -300,300">
                              Exprimez simplement votre besoin. <br/> Nous nous occupons du reste.</h2>
                          <QuotationButton/>
                      </div>
                  </div>
              </li>
              <li className="slideshow-item"><img src="/assets/img/cover/cover-sme-2.jpeg" alt={"image"} data-uk-cover={true} />
                  <div className="slideshow-item__content">
                      <div className="uk-position-center uk-position-small uk-text-center">
                          <div className="header-icons" data-uk-slideshow-parallax="x: -200,200">
                              <span></span><span></span><span></span></div>
                          <p className="slideshow-item__desc"
                             data-uk-slideshow-parallax="x: 200,-200">Des engins suivis et entretenus ! </p>
                          <h2 className="slideshow-item__title" data-uk-slideshow-parallax="x: -300,300">
                              Nous vous proposons des engins <br/> parfaitement entretenus</h2>
                          <QuotationButton/>
                      </div>
                  </div>
              </li>
              <li className="slideshow-item"><img src="/assets/img/cover/cover-sme-3.jpeg" alt={"image"} data-uk-cover={true} />
                  <div className="slideshow-item__content">
                      <div className="uk-position-center uk-position-small uk-text-center">
                          <div className="header-icons" data-uk-slideshow-parallax="x: -200,200">
                              <span></span><span></span><span></span></div>
                          <p className="slideshow-item__desc"
                             data-uk-slideshow-parallax="x: 200,-200">
                              Vous vous faites des soucis à propos des coûts?
                          </p>
                          <h2 className="slideshow-item__title" data-uk-slideshow-parallax="x: -300,300">
                              Exprimez simplement votre besoin, nous nous occupons du reste.</h2>
                          <QuotationButton/>
                      </div>
                  </div>
              </li>
              <li className="slideshow-item"><img src="/assets/img/cover/cover-6.jpeg" alt={"image"} data-uk-cover={true} />
                  <div className="slideshow-item__content">
                      <div className="uk-position-center uk-position-small uk-text-center">
                          <div className="header-icons" data-uk-slideshow-parallax="x: -200,200">
                              <span></span><span></span><span></span></div>
                          <p className="slideshow-item__desc"
                             data-uk-slideshow-parallax="x: 200,-200">
                              Offrez-vous les meilleurs équipements de travail et de protection.
                          </p>
                          <h2 className="slideshow-item__title" data-uk-slideshow-parallax="x: -300,300">
                              Nous vous offrons une large gamme d'équipements de qualité au meilleurs prix.</h2>
                          <QuotationButton/>
                      </div>
                  </div>
              </li>
              <li className="slideshow-item"><img src="/assets/img/cover/cover-sme-4.jpeg" alt={"image"} data-uk-cover={true} />
                  <div className="slideshow-item__content">
                      <div className="uk-position-center uk-position-small uk-text-center">
                          <div className="header-icons" data-uk-slideshow-parallax="x: -200,200">
                              <span></span><span></span><span></span></div>
                          <p className="slideshow-item__desc"
                             data-uk-slideshow-parallax="x: 200,-200">
                              Profitez de notre expertise.
                          </p>
                          <h2 className="slideshow-item__title" data-uk-slideshow-parallax="x: -300,300">
                              Nous vous offrons les meilleurs conseils pour le choix de vos équipements miniers plus efficaces avec moins de consommation.</h2>
                          <QuotationButton/>
                      </div>
                  </div>
              </li>
          </ul>
          <div className="uk-visible@m"><a className="uk-position-center-left uk-position-small" href="src/components/home/index#"
                                           data-uk-slidenav-previous={true} data-uk-slideshow-item="previous"></a><a
              className="uk-position-center-right uk-position-small" href="src/components/home/index#" data-uk-slidenav-next={true}
              data-uk-slideshow-item="next"></a></div>
      </div>
  </div>
}
export default SlidersSection;