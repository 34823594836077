import React from "react";

const QuotationButton = () => {

    return <>
        <div className="uk-align-center">
            <a href="/enquiry" className="uk-button uk-button-large" type="submit">
                <span>Demander un devis</span>
            </a>
        </div>
    </>
}

export default QuotationButton;